const AvantiFront = {
    initialization: function () {
        this.login();
        this.offerFormReCaptcha();
        this.offerForm();
        this.contactFormReCaptcha();
        this.contactForm();
        this.mainSlider();
        this.specialOfferSlider();
        this.recommendedRealEstatesSlider();
        this.printOffer();
        this.filterRealEstates();
        this.scrollImagesRealEstate();
        this.inputNumberValidation();
        this.calcCostsRealEstates();
        this.calcOfferSpecificRealEstate();
    },

    login: function () {
        $('#formlogin').on('submit', function (e) {
            e.preventDefault();

            $.ajax({
                url: 'login.php',
                data: $(this).serialize(),
                type: 'POST',
                cache: false,
                beforeSend: function () {
                    $('#inlog').val('Logowanie...');
                },
                success: function (data) {
                    if (data === "true") {
                        window.location.href = "admin.php";
                    } else {
                        $('#errorlogin').show();
                        $('#errorlogin').fadeOut(10000);
                    }
                }
            });
        });
    },

    contactFormReCaptcha: function () {
        $('#submitContactForm').on('click', function (e) {
            e.preventDefault();

            grecaptcha.ready(function () {
                grecaptcha.execute(
                    '6Le7p1EqAAAAACfJjo5wf42zTOAXu6NaPNo-_HQl',
                    {action: 'submit'}).then(
                    function (token) {
                        $('#token').val(token);
                        $('#contactform').submit();
                    });
            });
        });
    },

    contactForm: function () {
        $('#contactform').on('submit', function (e) {
            e.preventDefault();

            $.ajax({
                url: 'mailer.php',
                data: $(this).serialize(),
                type: 'POST',
                success: function () {
                    $("#successmail").show().fadeOut(5000);
                }
            });
        });
    },

    offerFormReCaptcha: function () {
        $('#submitZgloszenieOferty').on('click', function (e) {
            e.preventDefault();

            grecaptcha.ready(function () {
                grecaptcha.execute(
                    '6Le7p1EqAAAAACfJjo5wf42zTOAXu6NaPNo-_HQl',
                    {action: 'submit'}).then(
                    function (token) {
                        $('#token').val(token);
                        $('#zgloszenieoferty').submit();
                    });
            });
        });
    },

    offerForm: function () {
        $('#zgloszenieoferty').on('submit', function (e) {
            $.ajax({
                url: 'nowaoferta.php',
                data: $(this).serialize(),
                beforeSend: function () {
                    $('#submitZgloszenieOferty').val('Wysyłanie oferty w toku...');
                },
                type: 'POST',
                success: function () {
                    $('#submitZgloszenieOferty').val('Wysłano pomyślnie ofertę do biura');
                }
            });
            e.preventDefault();
        });
    },

    mainSlider: function () {
        $('#slider').nivoSlider();
    },

    specialOfferSlider: function () {
        $('.bxslider').bxSlider({
            minSlides: 4,
            maxSlides: 4,
            slideWidth: 160,
            slideMargin: 0,
            ticker: true,
            speed: 30000
        });
    },

    recommendedRealEstatesSlider: function () {
        var dd = $('.vticker').easyTicker({
            direction: 'down',
            easing: 'easeInOutBack',
            speed: 'slow',
            interval: 3500,
            height: 'auto',
            visible: 3,
            mousePause: true
        }).data('easyTicker');

        var cc = 1;
        $('.aa').click(function () {
            $('.vticker ul').append('<li>' + cc + ' Triangles can be made easily using CSS also without any images. This trick requires only div tags and some</li>');
            cc++;
        });

        $('.vis').click(function () {
            dd.options['visible'] = 3;

        });

        $('.visall').click(function () {
            dd.stop();
            dd.options['visible'] = 0;
            dd.start();
        });
    },

    printOffer: function () {
        $('#printconversion').on('click', function () {

            const offerToPrint = document.getElementById('content').innerHTML;

            const newWindow = window.open('', '', 'height=500,width=800');
            newWindow.document.write('<html><head><title>Drukowanie oferty</title></head>');
            newWindow.document.write(offerToPrint);
            newWindow.document.write('</body></html>');
            newWindow.document.close();
            newWindow.focus();
            newWindow.print();
            newWindow.close();
        });
    },

    filterRealEstates: function () {
        $('#selectcity').change(function (e) {
            $(this).closest('form').trigger('submit');
            $.ajax({
                url: 'formcity.php',
                data: $('#formall').serialize(),
                type: 'POST',
                cache: false,
                success: function (data) {
                    $("#list").html(data);
                }
            });
            e.preventDefault();
        });

        $('#selecthousing').change(function (e) {
            $(this).closest('form').trigger('submit');
            $.ajax({
                url: 'formcity.php',
                data: $('#formall').serialize(),
                type: 'POST',
                cache: false,
                success: function (data) {
                    $("#list").html(data);
                }
            });
            e.preventDefault();
        });

        $('#selectstreet').change(function (e) {
            $(this).closest('form').trigger('submit');
            $.ajax({
                url: 'formcity.php',
                data: $('#formall').serialize(),
                type: 'POST',
                cache: false,
                success: function (data) {
                    $("#list").html(data);
                }
            });
            e.preventDefault();
        });

        $('#selectroom').change(function (e) {
            $(this).closest('form').trigger('submit');
            $.ajax({
                url: 'formcity.php',
                data: $('#formall').serialize(),
                type: 'POST',
                cache: false,
                success: function (data) {
                    $("#list").html(data);
                }
            });
            e.preventDefault();
        });

        $('#selectareaod').on("change paste keyup", function (e) {
            $(this).closest('form').trigger('submit');
            $.ajax({
                url: 'formcity.php',
                data: $('#formall').serialize(),
                type: 'POST',
                cache: false,
                success: function (data) {
                    $("#list").html(data);
                }
            });
            e.preventDefault();
        });

        $('#selectareado').on("change paste keyup", function (e) {
            $(this).closest('form').trigger('submit');
            $.ajax({
                url: 'formcity.php',
                data: $('#formall').serialize(),
                type: 'POST',
                cache: false,
                success: function (data) {
                    $("#list").html(data);
                }
            });
            e.preventDefault();
        });

        $('#selectpriceod').on("change paste keyup", function (e) {
            $(this).closest('form').trigger('submit');
            $.ajax({
                url: 'formcity.php',
                data: $('#formall').serialize(),
                type: 'POST',
                cache: false,
                success: function (data) {
                    $("#list").html(data);
                }
            });
            e.preventDefault();
        });

        $('#selectpricedo').on("change paste keyup", function (e) {
            $(this).closest('form').trigger('submit');
            $.ajax({
                url: 'formcity.php',
                data: $('#formall').serialize(),
                type: 'POST',
                cache: false,
                success: function (data) {
                    $("#list").html(data);
                }
            });
            e.preventDefault();
        });
    },

    scrollImagesRealEstate: function () {
        $('#galeria').scrollbox({
            linear: true,
            startDelay: 2,
            delay: 3,
            step: 5,
            speed: 20,
            switchItems: 1,
            direction: 'vertical',
            distance: 'auto',
            autoPlay: true,
            onMouseOverPause: true,
            paused: false,
            queue: null
        });
    },

    inputNumberValidation: function () {
        $('.numberValidation').on('input', function () {
            this.value = this.value.replace(/\D/g, '');
        });
    },

    calcCostsRealEstates: function () {
        $('form#choose').show();
        $('form#calc').hide();
        $('#printoffert').hide();

        $('form#choose').click(function () {
            if ($('#notarialne').is(':checked')) {
                $.ajax({
                    url: 'danekalkulator.php',
                    data: {'id': 1},
                    type: 'POST',
                    cache: false,
                    success: function (data) {
                        const calcs = JSON.parse(data);

                        $('form#calc').show();

                        $('#oplatasadowa').val(calcs.oplata_sadowa);
                        $('#wpisdoksiegi').val(calcs.wpis_do_ksiegi_wieczystej);
                        $('#wypis').val(calcs.wypis_aktu_notarialnego);

                        $('#printoffert').show();
                        $('html,body').animate({scrollTop: $('#cenanieruchomosci').offset().top}, 1000);

                        $("#cenanieruchomosci").keyup(function () {
                            var cena = $("#cenanieruchomosci").val();
                            var taksanotarialna = 0;

                            if (cena <= 3000) {
                                taksanotarialna = 100;
                            }
                            if (cena > 3000 && cena <= 10000) {
                                taksanotarialna = 100 + 0.03 * (cena - 3000);
                            }
                            if (cena > 10000 && cena <= 30000) {
                                taksanotarialna = 310 + 0.02 * (cena - 10000);
                            }
                            if (cena > 30000 && cena <= 60000) {
                                taksanotarialna = 710 + 0.01 * (cena - 30000);
                            }
                            if (cena > 60000 && cena <= 1000000) {
                                taksanotarialna = 1010 + 0.004 * (cena - 60000);
                            }
                            if (cena > 1000000 && cena <= 2000000) {
                                taksanotarialna = 4770 + 0.002 * (cena - 1000000);
                            }
                            if (cena > 2000000) {
                                taksanotarialna = 6770 + 0.0025 * (cena - 2000000);
                                if (taksanotarialna > 10000) {
                                    taksanotarialna = 10000;
                                }
                            }
                            var nowacena = (cena * calcs.podatek_od_czynnosci_cywilnoprawnych) + (0.5 * taksanotarialna) + (0.23 * 0.5 * taksanotarialna) + calcs.oplata_sadowa +
                                calcs.wpis_do_ksiegi_wieczystej + calcs.wypis_aktu_notarialnego + (calcs.prowizja_agencji * cena) + (0.23 * calcs.prowizja_agencji * cena);

                            $("#sumadodatek").val(nowacena.toFixed(2));
                            var razem = parseFloat(cena) + parseFloat(nowacena);

                            $("#podatek").val(parseFloat(calcs.podatek_od_czynnosci_cywilnoprawnych * cena).toFixed(2));

                            $("#taksa").val(0.5 * taksanotarialna);
                            $("#vattaksa").val((0.23 * 0.5 * taksanotarialna).toFixed(2));
                            $("#oplatasadowa").val(calcs.oplata_sadowa);
                            $("#wpisdoksiegi").val(calcs.wpis_do_ksiegi_wieczystej);
                            $("#wypis").val(calcs.wypis_aktu_notarialnego);
                            $("#prowizja").val(calcs.prowizja_agencji * cena);

                            $("#vatprowizja").val(parseFloat(0.23 * calcs.prowizja_agencji * cena).toFixed(2));
                            $("#razem").val(razem.toFixed(2));

                            if ($("#cenanieruchomosci").val().length === 0) {
                                $("#sumadodatek").val("");
                                $("#razem").val("");
                            }
                        });
                    }
                });
            }

            if ($('#spoldzielcze').is(':checked')) {
                $.ajax({
                    url: 'danekalkulator.php',
                    data: {'id': 2},
                    type: 'POST',
                    cache: false,
                    success: function (data) {
                        const calcs = JSON.parse(data);

                        $('form#calc').show();

                        $('#oplatasadowa').val('');
                        $('#wpisdoksiegi').val('');
                        $('#wypis').val(calcs.wypis_aktu_notarialnego);
                        $('#printoffert').show();
                        $('html,body').animate({scrollTop: $('#cenanieruchomosci').offset().top}, 1000);

                        $("#cenanieruchomosci").keyup(function () {
                            var cena = $("#cenanieruchomosci").val();
                            var taksanotarialna2 = 0;
                            if (cena <= 3000) {
                                taksanotarialna2 = 100;
                            }
                            if (cena > 3000 && cena <= 10000) {
                                taksanotarialna2 = 100 + 0.03 * (cena - 3000);
                            }
                            if (cena > 10000 && cena <= 30000) {
                                taksanotarialna2 = 310 + 0.02 * (cena - 10000);
                            }
                            if (cena > 30000 && cena <= 60000) {
                                taksanotarialna2 = 710 + 0.01 * (cena - 30000);
                            }
                            if (cena > 60000 && cena <= 1000000) {
                                taksanotarialna2 = 1010 + 0.004 * (cena - 60000);
                            }
                            if (cena > 1000000 && cena <= 2000000) {
                                taksanotarialna2 = 4770 + 0.002 * (cena - 1000000);
                            }
                            if (cena > 2000000) {
                                taksanotarialna2 = 6770 + 0.0025 * (cena - 2000000);
                                if (taksanotarialna2 > 10000) {
                                    taksanotarialna2 = 10000;
                                }
                            }
                            var nowacena = cena * calcs.podatek_od_czynnosci_cywilnoprawnych + 0.5 * taksanotarialna2 + 0.23 * 0.5 * taksanotarialna2 + calcs.oplata_sadowa +
                                calcs.wpis_do_ksiegi_wieczystej + calcs.wypis_aktu_notarialnego + calcs.prowizja_agencji * cena + 0.23 * calcs.prowizja_agencji * cena;
                            $("#sumadodatek").val(nowacena.toFixed(2));
                            var razem = parseFloat(cena) + parseFloat(nowacena);
                            $("#podatek").val(parseFloat(calcs.podatek_od_czynnosci_cywilnoprawnych * cena).toFixed(2));
                            $("#taksa").val(0.5 * taksanotarialna2);
                            $("#vattaksa").val((0.23 * 0.5 * taksanotarialna2).toFixed(2));
                            $("#oplatasadowa").val(calcs.oplata_sadowa);
                            $("#wpisdoksiegi").val(calcs.wpis_do_ksiegi_wieczystej);
                            $("#wypis").val(calcs.wypis_aktu_notarialnego);
                            $("#prowizja").val(calcs.prowizja_agencji * cena);

                            $("#vatprowizja").val(parseFloat(0.23 * calcs.prowizja_agencji * cena).toFixed(2));
                            $("#razem").val(razem.toFixed(2));

                            if ($("#cenanieruchomosci").val().length === 0) {
                                $("#sumadodatek").val("");
                                $("#razem").val("");
                            }
                        });
                    }
                });
            }

            if ($('#pozostale').is(':checked')) {
                $.ajax({
                    url: 'danekalkulator.php',
                    data: {'id': 3},
                    type: 'POST',
                    cache: false,
                    success: function (data) {
                        const calcs = JSON.parse(data);

                        $('form#calc').show();

                        $('#oplatasadowa').val(calcs.oplata_sadowa);
                        $('#wpisdoksiegi').val(calcs.wpis_do_ksiegi_wieczystej);
                        $('#wypis').val(calcs.wypis_aktu_notarialnego);
                        $('#printoffert').show();
                        $('html,body').animate({scrollTop: $('#cenanieruchomosci').offset().top}, 1000);

                        $("#cenanieruchomosci").keyup(function () {
                            var cena = $("#cenanieruchomosci").val();
                            var taksanotarialna3 = 0;
                            if (cena <= 3000) {
                                taksanotarialna3 = 100;
                            }
                            if (cena > 3000 && cena <= 10000) {
                                taksanotarialna3 = 100 + 0.03 * (cena - 3000);
                            }
                            if (cena > 10000 && cena <= 30000) {
                                taksanotarialna3 = 310 + 0.02 * (cena - 10000);
                            }
                            if (cena > 30000 && cena <= 60000) {
                                taksanotarialna3 = 710 + 0.01 * (cena - 30000);
                            }
                            if (cena > 60000 && cena <= 1000000) {
                                taksanotarialna3 = 1010 + 0.004 * (cena - 60000);
                            }
                            if (cena > 1000000 && cena <= 2000000) {
                                taksanotarialna3 = 4770 + 0.002 * (cena - 1000000);
                            }
                            if (cena > 2000000) {
                                taksanotarialna3 = 6770 + 0.0025 * (cena - 2000000);
                                if (taksanotarialna3 > 10000) {
                                    taksanotarialna3 = 10000;
                                }
                            }
                            var nowacena = cena * calcs.podatek_od_czynnosci_cywilnoprawnych + taksanotarialna3 + 0.23 * taksanotarialna3 + calcs.oplata_sadowa +
                                calcs.wpis_do_ksiegi_wieczystej + calcs.wypis_aktu_notarialnego + calcs.prowizja_agencji * cena + 0.23 * calcs.prowizja_agencji * cena;
                            $("#sumadodatek").val(nowacena.toFixed(2));
                            var razem = parseFloat(cena) + parseFloat(nowacena);
                            $("#podatek").val(parseFloat(calcs.podatek_od_czynnosci_cywilnoprawnych * cena).toFixed(2));
                            $("#taksa").val(taksanotarialna3);
                            $("#vattaksa").val((0.23 * taksanotarialna3).toFixed(2));
                            $("#oplatasadowa").val(calcs.oplata_sadowa);
                            $("#wpisdoksiegi").val(calcs.wpis_do_ksiegi_wieczystej);
                            $("#wypis").val(calcs.wypis_aktu_notarialnego);
                            $("#prowizja").val(calcs.prowizja_agencji * cena);
                            $("#vatprowizja").val(parseFloat(0.23 * calcs.prowizja_agencji * cena).toFixed(2));
                            $("#razem").val(razem.toFixed(2));

                            if ($("#cenanieruchomosci").val().length === 0) {
                                $("#sumadodatek").val("");
                                $("#razem").val("");
                            }
                        });
                    }
                });
            }
        });
    },

    calcOfferSpecificRealEstate: function () {
        let dataParams = new URLSearchParams(window.location.search);

        const typ = dataParams.get('typ');
        const cena = dataParams.get('cena');

        if (typ === "własność") {
            $('#notarialne').prop("checked", true).trigger("click");
            $('#cenanieruchomosci').prop("value", cena).trigger("keyup");
        }
        if (typ === "spółdzielcze własnościowe") {
            $('#spoldzielcze').prop("checked", true).trigger("click");
            $('#cenanieruchomosci').prop("value", cena).trigger("keyup");
        }
        if (typ === "spółdzielcze własnościowe z księgą wieczystą") {
            $('#pozostale').prop("checked", true).trigger("click");
            $('#cenanieruchomosci').prop("value", cena).trigger("keyup");
        }
    },
}

export default AvantiFront;